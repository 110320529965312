import { EPlatforms } from 'platformsConstants';
import { JOB_SOURCE } from 'redux/runHistory/types';
import { SearchTermsValidationResult } from 'redux/searchTermsAnalysis/types';

export enum UpdateApi {
  DIRECT = 'direct',
  TRACKING = 'tracking',
  DROP_DIRECT = 'drop_direct',
}

export type RequestType = {
  platform: EPlatforms;
  organization_id: string;
};

export type RunJobArgsType = {
  platform: EPlatforms;
  organization_id: string;
  update_api?: UpdateApi;
  adAccounts: string[];
};

export type GetContentType = {
  jobId: string;
  adAccountId: string;
  skipped: number;
  updated: number;
  error: number;
  notSupported: number;
  partially_updated: number;
  total: number;
  createdAt: string;
  platform: EPlatforms;
  jobStatus: JOB_STATUSES;
  jobSource: JOB_SOURCE;
};

export enum JOB_STATUSES {
  NO_CAMPAIGN_GROUPS = 'NO_CAMPAIGN_GROUPS',
  NO_CAMPAIGNS = 'NO_CAMPAIGNS',
  NO_CREATIVES = 'NO_CREATIVES',
  NO_FILTERED_CREATIVES = 'NO_FILTERED_CREATIVES',
}

export type GetLastRunType = {
  lastRun: GetContentType[];
  nextRun: NextJobType;
};

export type GetValidationJobSummary = {
  lastRun: LastRunSummary[];
  nextRun: NextJobType;
  periodSummary: PeriodSummary[];
};

export type LastRunSummary = {
  adAccountId: string;
  createdAt: string;
  excluded: number;
  included: number;
  platform: EPlatforms;
  jobId: string;
};

export type PeriodSummary = {
  adAccountId: string;
  excluded: number;
  total: number;
  proportion: number;
  excludedCost: number;
  calculatedExcludedCost: number;
};

export type NextJobType = {
  nextJobTime: string | null;
  status: string | null;
};

export type RunJobContentType = {
  updatedAssets: Asset[];
  summary: {
    [key: string]: {
      errors: number;
      skipped: number;
      updated: number;
      partially_updated: number;
      not_supported: number;
    };
  };
  isConnectionError: boolean;
  jobId: string;
  jobSource: JOB_SOURCE;
};

export type Asset = {
  status: 'fulfilled' | 'rejected';
  value: Array<{
    adAccountRessourceId: string;
    status: 'skipped' | 'updated';
  }>;
  reason: {
    error: {
      code?: number;
      msg?: string;
    };
    errorMetaData: {
      errorType: string;
      adAccounts: Array<string>;
      area: string;
      action: string;
    };
  };
};

export enum LINKEDIN_ASSETS_AREAS {
  CAMPAIGN_GROUPS = 'campaignGroups',
  CAMPAIGN = 'campaign',
  CREATIVES = 'creatives',
  RESOLVED_REFERENCES = 'resolvedReferences',
  TRACKING_PARAMETERS = 'trackingParameters',
  AD_ACCOUNT = 'adAccount',
}

export type ValidationJobDTO = {
  organization_id: string;
  adAccounts: string[];
};

export enum JobStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
  NO_RESULT = 'no_result',
}

export type JobResponse = {
  [adAccountId: string]: SearchTermsValidationResult[];
};
